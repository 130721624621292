import React from 'react'
import {Form, Grid, TextArea} from 'semantic-ui-react';

const CommentField = (params) => {

  const {
    title = 'Komentarz',
    comment = '',
    description = '',
    editable = false,
    visible = false,
    onUpdate = () => {}
  } = params

  return (
    <>
      {visible && (
        <Grid.Row>
          <Grid.Column> {
            editable ? (
              <Form>
                <Form.Field>
                  <label>{title}</label>
                  <TextArea
                    onChange={e => onUpdate(e.target.value)}
                    value={comment}
                  ></TextArea>
                  {description && (<span className="helper-text">{description}</span>)}
                </Form.Field>
              </Form>
            )
            : (
              <>
                <strong>{title} </strong>
                <p>{comment}</p>
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  )
}

export default CommentField
