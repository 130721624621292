import './css/style.css';
import {BrowserRouter as Router, Route} from 'react-router-dom'

import Home from './pages/Home'
import EquipmentOrder from './pages/EquipmentOrder'

import ShowValuations from './pages/adm/equipmentOrder/ShowValuations'

function Main() {
  return (
    <Router>
      <Route path ='/' exact component={Home} />
      <Route path ='/equipmentOrder/:command/:veId/:secureKey' component={EquipmentOrder} />

      <Route path ='/adm/module/equipmentOrder/showValuations/:orderHash/:adminHash' component={ShowValuations} />
    </Router>
  );
}

export default Main;
