import md5 from 'md5'
import {API_HOST} from '../../../config'

import React, {useState, useEffect} from 'react'
import {Dimmer, Header, Image, List, Loader, Message, Segment} from 'semantic-ui-react'

import ValuationAcceptance from '../../../components/admin/ValuationAcceptance'
import ValuationAccepted from '../../../components/admin/ValuationAccepted'
import DialogConfirm from '../../../components/admin/DialogConfirm'

const ShowValuations = ({match: {params: {orderHash, adminHash}}}) => {

  const [loading, setLoading] = useState(true)
  const [dimmer, setDimmer] = useState(false)
  const [error, setError] = useState([])
  const [fatalError, setFatalError] = useState('')
  const [order, setOrder] = useState({})
  const [modal, setModal] = useState({
    visible: false,
    data: {}
  })

  const URI = `${API_HOST}/api/adm/module/equipmentOrder/showValuations/${orderHash}/${adminHash}`

  useEffect(() => {
    fetch(URI)
    .then(async res => {
      const json = await res.json();
      if (res.status !== 200) {
        setFatalError(json.msg)
      }
      return json
    })
    .then(data => {
      if (data.success === false) {
        return setError(Array.isArray(data.msg) ? data.msg : [data.msg])
      }
      setOrder(data.order)
    })
    .catch(err => setFatalError(err.message))
    .finally(() => setLoading(false))
  }, [])

  // Save data
  const pickValuation = (veHash) => {
    setDimmer(true)
    setError([])
    fetch(URI, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        veHash,
      })
    })
    .then(res => res.json())
    .then(data => {
      if (data.success === false) {
        return setError(Array.isArray(data.msg) ? data.msg : [data.msg])
      }
      setOrder(data.order)
    })
    .catch(err => setFatalError(err.message))
    .finally(() => setDimmer(false))
  }

  return (
    <>
    {loading && (
      <Segment clearing>
        <Header hidden={!loading} as='h1'>Ładuję dane...</Header>
      </Segment>
    )}
    {fatalError && (
      <Message 
        error
        header='Błąd pobierania danych'
        content={fatalError}
      />
    )}
    {!loading && !fatalError && (
      <Segment>
        <Dimmer inverted active={dimmer}>
          <Loader>Proszę czekać...</Loader>
        </Dimmer>
        <Segment>
          <Header as='h2'>{order.title} [#{order.issueId}]</Header>
          <span>ID zamówienia: {order._id}</span>
          <Header as='h4'>Szczegóły zamówienia:</Header>

          <List relaxed>
            <List.Item>
              <Image avatar src={`https://www.gravatar.com/avatar/${md5(order.orderingPerson.email)}`} />
              <List.Content>
                <List.Header>Zamawiający</List.Header>
                {order.orderingPerson.name}
              </List.Content>
            </List.Item>
            <List.Item>
              <Image avatar src={`https://www.gravatar.com/avatar/${md5(order.decisionPerson.email)}`} />
              <List.Content>
                <List.Header>Osoba akceptująca</List.Header>
                {order.decisionPerson.name}
              </List.Content>
            </List.Item>
          </List>
        </Segment>

        {order.selectedEntityId
          ? (<ValuationAccepted order={order} />)
          : (<ValuationAcceptance
              order = {order}
              acceptEntity = {veHash => {
                setModal({
                  data: order.valuationEntities.filter(o => o._id === veHash)[0],
                  visible: true
                })
              }}
            />)
        }
        
        {modal.visible && (
          <DialogConfirm
            title='Na pewno chcesz zatwierdzić tę wycenę?'
            data={modal.data}
            onClick={(accepted, data) => {
              setModal({...modal, visible: false})
              return accepted && pickValuation(data._id)
            }}
          />
        )}

      </Segment>
    )}
    </>
  )
}

export default ShowValuations
