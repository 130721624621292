import React from 'react'
import {Form, Grid, Input} from 'semantic-ui-react';

const SmallEditableText = (params) => {

  const {
    type = 'text',
    title = 'Komentarz',
    value = '',
    description = '',
    editable = false,
    visible = false,
    onUpdate = () => {}
  } = params

  return (
    <>
      {visible && (
        <Grid.Row>
          <Grid.Column> {
            editable ? (
              <Form>
                <Form.Field>
                  <label>{title}</label>
                  <Input
                    onChange={e => onUpdate(e.target.value)}
                    type={type}
                    min='1'
                    value={value}
                  ></Input>
                  {description && (<span className="helper-text">{description}</span>)}
                </Form.Field>
              </Form>
            )
            : (<p><strong>{title}</strong> {value}</p>)}
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  )
}

export default SmallEditableText
