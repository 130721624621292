import {Table} from 'semantic-ui-react'
import NumberFormat from 'react-number-format';

const OrderTableRow = ({entry, editable, onRowUpdate}) => {
  return (
    <Table.Row>
      <Table.Cell>{entry.name}</Table.Cell>
      <Table.Cell>{entry.quantity}</Table.Cell>
      <Table.Cell>
        <NumberFormat
          value={entry.value === -1 ? 0 : entry.value}
          displayType={editable ? 'text' : 'input'}
          onValueChange={values => onRowUpdate(entry._id, values)}
          suffix=' zł'
          allowEmptyFormatting={true}
          thousandSeparator=' '
          decimalSeparator=','
          decimalScale={2}
          fixedDecimalScale={true}
          allowedDecimalSeparators={[',','.']}
          allowNegative={false}
        />
      </Table.Cell>
      <Table.Cell textAlign='right'>
        <NumberFormat
          value={entry.quantityValue === -1 ? 0 : entry.quantityValue}
          displayType={'text'}
          suffix=' zł'
          thousandSeparator=' '
          decimalSeparator=','
          decimalScale={2}
          fixedDecimalScale={true}
          allowedDecimalSeparators={[',','.']}
          allowNegative={false}
        />
        </Table.Cell>
    </Table.Row>
  )
}

export default OrderTableRow