import React from 'react'
import {Button, Card, Header, Image} from 'semantic-ui-react'
import NumberFormat from 'react-number-format'
import md5 from 'md5'

import OrderTable from '../OrderTable'

const AcceptOrderCard = ({entity, showAcceptButton, acceptEntity = () => {}}) => {
  return (
    <Card color={entity.isCompleted ? 'green' : 'red'}>
      <Card.Content >
        <Image
          src={`https://www.gravatar.com/avatar/${md5(entity.email)}`}
          floated='right'
          avatar
          size='massive'
        />
        <Card.Header>{entity.name}</Card.Header>
        
        <Card.Meta>
          <span>ID: {entity._id}</span><br />
          <span>Email: <a href={`mailto:${entity.email}`}>{entity.email}</a></span><br />
          {entity.isCompleted
            ? (<span>Wyceniony: <strong>{entity.updatedOn.split('T')[0]}</strong></span>)
            : (<p>Nie wyceniony</p>)
          }
        </Card.Meta>

        {entity.isCompleted && (
          <Card.Description>
            <OrderTable val={entity} />
            {entity.completionTimeLimit && (<><strong>Termin realizacji:</strong><p>{entity.completionTimeLimit} dni</p></>)}
            {entity.comment && (<><strong>Komentarz:</strong><p>{entity.comment}</p></>)}
          </Card.Description>
        )}
      </Card.Content>

      {showAcceptButton && (
        <Card.Content extra>
          <div className='ui two buttons'>
            <Button basic color='green' onClick={() => {acceptEntity(entity._id)}}>Zatwierdzam tę wycenę</Button>
          </div>
        </Card.Content>
      )}
    </Card>
  )
}

export default AcceptOrderCard
