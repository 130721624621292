import {useState} from 'react'
import {Header, Form, Grid, Table} from 'semantic-ui-react'
import OrderTableRow from './OrderTableRow'
import NumberFormat from 'react-number-format';

const OrderTable = ({val = {entries: [], totalValue: 0}, editable = true, onUpdate = () => {}}) => {
  const [valuation, setValuation] = useState(val)

   // On update single row
   const onRowUpdate = (_id, values) => {
    setValuation(valuation => {
      valuation.entries = valuation.entries.map(entry => {
        if (entry._id === _id) {
          entry.value = values.floatValue
        }
        return entry
      })
      return {...valuation};
    })
    recalculateValuation()
    onUpdate(valuation)
  }

  const recalculateValuation = () => {
    setValuation(valuation => {
      let totalValue = 0;
      valuation.entries = valuation.entries.map(entry => {
        entry.quantityValue = entry.quantity * entry.value

        // Sum up total value
        totalValue += entry.quantityValue

        return entry
      })
      valuation.totalValue = totalValue;
      return {...valuation};
    })
  }

  return (
    <Grid.Row>
      <Grid.Column>
        {valuation.entries.length > 0 && (
          <Form>
            <div className='semantic-table-custom-wrapper'>
              <Table unstackable singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Element</Table.HeaderCell>
                    <Table.HeaderCell>Sztuk</Table.HeaderCell>
                    <Table.HeaderCell>Cena brutto (za szt.)</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Cena brutto</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {valuation.entries.map(entry => (
                    <OrderTableRow
                      key={entry._id}
                      entry={entry}
                      editable={editable}
                      onRowUpdate={onRowUpdate}
                    />
                  ))}
                </Table.Body>
              </Table>
            </div>
        
            <Header as='h2' floated='right' className='total-price'>Suma brutto:&nbsp;
              <NumberFormat
                value={valuation.totalValue}
                displayType={'text'}
                suffix='&nbsp;zł'
                thousandSeparator='&nbsp;'
                decimalSeparator=','
                decimalScale={2}
                fixedDecimalScale={true}
                allowedDecimalSeparators={[',','.']}
                allowNegative={false}
              />
            </Header>
          </Form>
        )}
      </Grid.Column>
    </Grid.Row>
  )
}

export default OrderTable
