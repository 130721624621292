import React from 'react'

const Home = () => {
  return (
    <div>
      <h1>Admin Brothers</h1>
    </div>
  )
}

export  default Home;