import React, {useRef, useState} from 'react'

import {Form, Grid, Input} from 'semantic-ui-react'

const AttachInvoice = ({label = '', placeholder = '', button = '', onChange = () => {}}) => {

  const [file, setFile] = useState(null);
  const fileInput = useRef(null)

  const onChangeFile = (e) => {
    const singleFile = e.target.files[0]
    setFile(singleFile)
    onChange(singleFile)
  }

  return (
    <Grid.Row>
      <Grid.Column>
        <Form>
          <Form.Field>
            <label>{label}</label>

            <input
              type='file'
              ref={fileInput}
              onChange={onChangeFile}
              accept='.pdf,.png,.jpg'
              hidden
            />

            <Input
              type='text'
              action={{
                content: button,
                onClick: () => fileInput.current.click()
              }}
              placeholder={placeholder}
              value={file?.name || ''}
              readOnly
            />

          </Form.Field>
        </Form>
      </Grid.Column>
    </Grid.Row>
  )
}

export default AttachInvoice
