import React from 'react'

import {Header, Card, Segment} from 'semantic-ui-react'
import AcceptOrderCard from './AcceptOrderCard'

const ValuationAccepted = ({order}) => {

  const acceptedOffer = order.valuationEntities.filter(entity => entity._id === order.selectedEntityId)
  const rejectedOffers = order.valuationEntities.filter(entity => entity._id !== order.selectedEntityId)

  return (
    <Segment>
      
      {<>
        <Header as='h2' color='green'>Wybrana wycena:</Header>
        <Card.Group itemsPerRow={1}>
          {acceptedOffer.length && (<AcceptOrderCard showAcceptButton={order.status === 'valuationInProgress'} entity={acceptedOffer[0]} /> )}
        </Card.Group>
      </>}

      {rejectedOffers.length > 0 && (<>
        <Header as='h2' color='red'>Pozostałe wyceny:</Header>
        <Card.Group itemsPerRow={2}>
          {rejectedOffers.map(entity => (
            <AcceptOrderCard
              key={entity._id}
              showAcceptButton={false}
              entity={entity}
            />
          ))}
        </Card.Group>
      </>)}

    </Segment>
  )
}

export default ValuationAccepted
