import React from 'react'
import {Button, Header, Modal} from 'semantic-ui-react'
import NumberFormat from 'react-number-format'

const DialogConfirm = ({title, data, onClick = () => {}}) => {
  return (
    <Modal size='tiny' open={true} >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Header as='h3' className='total-price'>Suma: {' '}
            <NumberFormat
              value={data.totalValue === -1 ? 0 : data.totalValue}
              displayType='text'
              suffix=' zł'
              allowEmptyFormatting={true}
              thousandSeparator=' '
              decimalSeparator=','
              decimalScale={2}
              fixedDecimalScale={true}
              allowedDecimalSeparators={[',','.']}
              allowNegative={false}
            />
        </Header>
        <Header as='h4'>{data.name}<br />{data.email}</Header>
        <Header as='h4'>Termin realizacji:<br />{data.completionTimeLimit}</Header>
        <Header as='h4'>Komentarz:<br />{data.comment}</Header>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={() => onClick(false)}>NIE</Button>
        <Button negative onClick={() => onClick(true, data)}>TAK</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default DialogConfirm
