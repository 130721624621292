class ConnectionClass {

  static _instance;

  constructor() {
    if (!ConnectionClass._instance) {
      ConnectionClass._instance = this;
    }
    return ConnectionClass._instance;
  }

  // Get data from server
  getData(uri) {
    return this.request(uri)
  }

  // Save data to server
  saveData(uri = '', data = null, file = null, method = '') {
    return this.request(uri, data, file, method)
  }

  // Perform request
  request(uri, data = null, file = null, method = '') {
    return new Promise((resolve, reject) => {
      let body = data ? JSON.stringify(data) : null

      if (file) {
        body = new FormData()
        body.append('file', file)
      }

      fetch(uri, {
        method: method ? method : (data || file) ? 'PUT' : 'GET',
        headers: data ? {'Content-type': 'application/json'} : {},
        body
      })
      .then(async res => {
        const json = await res.json()

        if (res.status !== 200) {
          return reject( json.err || `${res.status} ${res.statusText}`)
        }

        resolve(json) 
      })
      .catch(err => reject(`Problem z połączeniem (${err.message})`))
    })
  }

}

const connection = new ConnectionClass()

export default connection