import React from 'react'

import {Header, Card, Segment} from 'semantic-ui-react'
import AcceptOrderCard from './AcceptOrderCard'

const ValuationAcceptance = ({order, acceptEntity}) => {

  const valuatedOffers = order.valuationEntities.filter(entity => entity.isCompleted)
  const notValuatedOffers = order.valuationEntities.filter(entity => !entity.isCompleted)

  return (
    <Segment>
      
      {valuatedOffers.length > 0 && (<>
        <Header as='h2' color='green'>Wyceny gotowe:</Header>
        <Card.Group itemsPerRow={2}>
          {valuatedOffers.map(entity => (
              <AcceptOrderCard
                key={entity._id}
                showAcceptButton = {order.selectedEntityId.length === 0}
                entity={entity}
                acceptEntity={acceptEntity}
              />
            )
          )}
        </Card.Group>
      </>)}

      {notValuatedOffers.length > 0 && (<>
        <Header as='h2' color='red'>Jeszcze nie wycenione:</Header>
        <Card.Group itemsPerRow={2}>
          {order.valuationEntities.map(entity => !entity.isCompleted && (
            <AcceptOrderCard
              key = {entity._id}
              showAcceptButton = {false}
              entity = {entity}
            />)
          )}
        </Card.Group>
      </>)}

    </Segment>
  )
}

export default ValuationAcceptance
