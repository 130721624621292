import React, {useEffect, useState} from 'react'
import {Button, Dimmer, Grid, Header, Message, Image, Label, List, Loader, Segment} from 'semantic-ui-react'
import md5 from 'md5'
import {API_HOST} from './../config'
import connection from '../js/connection'
import OrderTable from '../components/OrderTable'
import CommentField from '../components/CommentField'
import AttachInvoice from '../components/AttachInvoice'
import SmallEditableText from '../components/SmallEditableText'

const EquipmentOrder = (attr) => {
  const {command, veId, secureKey} = attr.match.params
  const URI = `${API_HOST}/api/equipmentOrder/${command}/${veId}/${secureKey}`

  // States
  const [loading, setLoading] = useState(true)
  const [dimmer, setDimmer] = useState(false)
  const [error, setError] = useState('')

  const [invoice, setInvoice] = useState(null)

  const [order, setOrder] = useState({
    acceptStatus: "",
    orderId: 0,
    valuation: {
      entries: [],
      totalValue: 0,
      isCompleted: false
    }
  })

  // Get data
  useEffect(() => {
    connection.getData(URI)
    .then(resp => {
      const isError = resp.err.length > 0
      isError && setError(resp.err)
      resp.data && setOrder(resp.data)
      setLoading(false)
    })
    .catch(err => setError(err))
  }, [])

  // Save data
  const saveData = (toSave = null, file = null, method = null) => {
    setError('')
    setDimmer(true)
    
    connection.saveData(URI, toSave, file, method)
    .then(resp => {
      const isError = resp.err.length > 0
      isError && setError(resp.err)
      !isError && resp.data && setOrder(resp.data)
    })
    .catch(err => setError(err))
    .finally(() => setDimmer(false))
  }

  const acceptReject = accept => {
    const newOrder = {
      ...order,
      acceptStatus: accept ? 'accepted' : 'rejected'
    }

    saveData(newOrder)
  }

  return (
    <Segment clearing>
      {loading && !error && (
        <Segment clearing>
          <Header hidden={!loading && !error} as='h1'>Ładuję dane...</Header>
        </Segment>
      )}

      {loading && error && (
        <Message
          error
          list = {Array.isArray(error) ? error : null}
          content = {!Array.isArray(error) && error}
        />
      )}

      {!loading && (
        <>
          <Dimmer inverted active={dimmer}>
            <Loader>Proszę czekać...</Loader>
          </Dimmer>

          <Segment clearing attached='top'>
            <Header as='h1'>Zamówienie #{order.orderId}</Header>
            {order.acceptStatus?.length > 0 && (
              <Label color={order.acceptStatus == 'accepted' ? 'green' : 'red'} ribbon>To zamówienie zostało {order.acceptStatus == 'accepted' ? 'zaakceptowane' : 'odrzucone'}</Label>
            )}
            {order.orderingPerson && (
              <List relaxed>
                <List.Item>
                  <Image avatar size='mini' src={`https://www.gravatar.com/avatar/${md5(order.orderingPerson.email)}`} />
                  <List.Content>
                    <List.Header>Zamawiający: {order.orderingPerson.name}</List.Header>
                    <a href={`mailto:${order.orderingPerson.email}`}>{order.orderingPerson.email}</a>
                  </List.Content>
                </List.Item>
              </List>
            )}

            {order.description && command === 'accept' && (
              <>
                <h5>Treść zamówienia osoby zamawiającej:</h5>
                <p>{order.description}</p>
              </>
            )}

          </Segment>

          <Segment clearing attached>
            
            {command === 'valuate' && order.acceptStatus?.length == 0 && !order.valuation.isCompleted && (
              <p>Prośba o wycenę następujących elementów.</p>
            )}

            {command === 'accept' && order.acceptStatus?.length == 0 && (
              <p>Prośba o wycenę zaakceptowanie lub odrzucenie wyceny elementów.</p>
            )}

            {command === 'invoice' && order.acceptStatus?.length > 0 && (
              <p>Proszę o załączenie faktury PDF do zamówienia</p>
            )}

            {order.deliveryAddress && (
              <p>Adres dostawy: <strong>{order.deliveryAddress}</strong></p>
            )}

            <Grid>
              <OrderTable
                val={order.valuation}
                editable={order.valuation.isCompleted}
                onUpdate={valuation => setOrder({
                  ...order,
                  valuation: {
                    ...valuation,
                    comment: order.valuation.comment
                  }
                })}
              />

              <SmallEditableText
                title = 'Termin realizacji (ilość dni):'
                value = {order.valuation.completionTimeLimit || ''}
                type = 'number'
                description = 'Proszę podać orientacyjny czas realizacji zamówienia po zaakceptowaniu wyceny'
                editable = {command === 'valuate' && !order.valuation.isCompleted}
                visible = {!order.valuation.isCompleted || (order.valuation.isCompleted && order.valuation.completionTimeLimit)}
                onUpdate = {completionTimeLimit => setOrder({
                  ...order,
                  valuation: {
                    ...order.valuation,
                    completionTimeLimit
                  }
                })}
              />

              <CommentField 
                title = 'Komentarz osoby wyceniającej:'
                comment = {order.valuation.comment || ''}
                description='Komentarz jest opcjonalny. Będzie przekazany do osoby akceptującej zamówienie oraz obsługi AdminBrothers'
                editable = {command === 'valuate' && !order.valuation.isCompleted}
                visible = {!order.valuation.isCompleted || (order.valuation.isCompleted && order.valuation.comment)}
                onUpdate = {comment => setOrder({
                  ...order,
                  valuation: {
                    ...order.valuation,
                    comment
                  }
                })}
              />

              <CommentField 
                title = 'Komentarz osoby akceptującej:'
                comment = {order.decisionPerson?.comment || ''}
                description='Komentarz jest opcjonalny. Będzie on przekazany wyłącznie do obsługi AdminBrothers'
                editable = {order.acceptStatus?.length < 1}
                visible = {command === 'accept'}
                onUpdate = {comment => setOrder({
                  ...order,
                  decisionPerson: {
                    ...order.decisionPerson,
                    comment
                  }
                })}
              />
            
              {command === 'invoice' && !order.isClosed && (
                <AttachInvoice 
                  label='Faktura PDF (lub zdjęcie faktury w formacie PNG lub JPG)'
                  placeholder='Faktura'
                  button='Wybierz plik...'
                  onChange={choosenFile => setInvoice(choosenFile)}
                />
              )}
            </Grid>
          </Segment>

          {error && (
            <Message
              error
              list = {Array.isArray(error) ? error : null}
              content = {!Array.isArray(error) && error}
            />
          )}

          {command === 'valuate' && !order.valuation.isCompleted && (
            <Segment clearing attached='bottom'>
              <Button.Group floated='right'>
                <Button
                  positive
                  type='submit'
                  content='Zapisz'
                  icon='right arrow'
                  labelPosition='right'
                  onClick={() => saveData(order)}
                />
              </Button.Group>
            </Segment>
          )}

          {command === 'invoice' && !order.isClosed && (
            <Segment clearing attached='bottom'>
              <Button.Group floated='right'>
                <Button
                  positive
                  type='submit'
                  content='Zapisz'
                  icon='right arrow'
                  labelPosition='right'
                  onClick={() => saveData(null, invoice, 'PUT')}
                />
              </Button.Group>
            </Segment>
          )}

          {command === 'accept' && order.acceptStatus?.length == 0 && (
            <Segment>
              <div className='ui two buttons'>
              <Button color='red' onClick={() => acceptReject(false)}>Odrzuć wycenę</Button>
              <Button color='green' onClick={() => acceptReject(true)}>Akceptuj wycenę</Button>
              </div>
            </Segment>
          )}
        </>
      )}
    </Segment>
  )
}

export default EquipmentOrder
